.App {
  width: 75vw;
  margin: 25px auto 50px;
  .title {
    text-align: center;
    margin-bottom: 50px;
  }
  .main-content {
    display: flex;

    justify-content: center;
  }
}

$boardDimension: 500px;

.board {
  flex-wrap: wrap;
  display: flex;
  width: $boardDimension;
  height: $boardDimension;

  .square {
    position: relative;
    width: 12.5%;
    height: 12.5%;
    &.black {
      background-color: rgba(0, 0, 0, 0.5);
    }
    &.white {
      background-color: rgba(0, 0, 0, 0.1);
    }
    &.highlight {
      background-color: darkred;
    }
    &.clickable-square {
      cursor: pointer;
      &:hover {
        background-color: darkred;
      }
    }

    .file {
      position: absolute;
      bottom: -25px;
      left: 50%;
      transform: translateX(-50%);
    }

    .rank {
      position: absolute;
      left: -25px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
.controls {
  width: 240px;
  margin-left: 25px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .control {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
  }
  .curr-answer {
    font-size: 48px;
  }

  .status {
    height: 20px;
    margin: 15px 0;
  }

  .correct {
    color: green;
  }

  .incorrect {
    color: red;
  }

  .prefs {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .other-prefs {
    margin-top: 40px;
  }
  .square-input {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

.btn {
  width: 80px;
  height: 30px;
  &:focus {
    outline: none;
  }

  &.start {
    background-color: lightgreen;
  }
  &.cancel {
    background-color: orange;
  }
}
